import { pushEvent } from '../pushEvent';

export const pushScrollTriggerPointEvent = ({
	scrollTopPercentage,
}: {
	scrollTopPercentage: number;
}) => {
	pushEvent({
		events: {
			category: 'interaction.scroll',
			action: 'scroll',
			label: `${scrollTopPercentage}%`,
		},
	});
};

export const pushReadingIntensity = ({
	triggerPoint,
	urlPath,
}: {
	triggerPoint: number;
	urlPath: string;
}) => {
	pushEvent({
		event: 'interaction.leesIntensiteit',
		events: {
			category: 'interaction.leesIntensiteit',
			action: urlPath,
			label: `${triggerPoint * 100}%`,
		},
	});
};
