import React from 'react';

import { WebApiPageData } from '@/globals/dataCollector/types/dataCollector.types';
import { pushReadingIntensity } from './events/pushScrollEvent';

// Configurations
const triggerPoints = [0.125, 0.25, 0.5, 0.75, 1] as const;
const averageWordsPerMS = 250 / 60 / 1000;
/**
 * If a user doesn't interact with the page for this amount of time
 * we consider the page as inactive and don't fire events
 * until the user interacts with the page again.
 */
const interactionThresholdMs = 30000;
const minimumWordCount = 150;

/**
 * On pages with a wordcount measure what percentage of the average required
 * time to read the article the user has been on the page.
 *
 * Send updates to GA at specified triggerPoints as long as user us actively engaged on a page.
 * When user is inactive for more than 30000 ms, we consider user as inactive and no longer engaging with the page.
 */
export const useMeasureReadingIntensity = ({
	urlPath,
	wordCount,
}: {
	wordCount: WebApiPageData['wordCount'];
	urlPath: WebApiPageData['url'];
}) => {
	React.useEffect(() => {
		if (wordCount == null || wordCount < minimumWordCount) return;

		let elapsedTime = 0;
		let lastInteractedTimestamp = performance.now();
		let lastFrameTimestamp: DOMHighResTimeStamp | undefined = performance.now();
		let frameTimer: number;
		const firedEvents: number[] = [];
		const articleReadingTimeMS = wordCount / averageWordsPerMS;

		const runFrame = (frameTimestamp: DOMHighResTimeStamp) => {
			const isStopped =
				document.visibilityState === 'hidden' ||
				frameTimestamp - lastInteractedTimestamp > interactionThresholdMs;
			if (!isStopped && lastFrameTimestamp != null) {
				const frameTimeDelta = frameTimestamp - lastFrameTimestamp;

				elapsedTime += frameTimeDelta;

				for (const triggerPoint of triggerPoints) {
					if (
						elapsedTime >= articleReadingTimeMS * triggerPoint &&
						!firedEvents.includes(triggerPoint)
					) {
						firedEvents.push(triggerPoint);
						pushReadingIntensity({
							triggerPoint,
							urlPath,
						});
					}
				}
			}

			lastFrameTimestamp = frameTimestamp;

			if (firedEvents.length < triggerPoints.length) {
				frameTimer = requestAnimationFrame(runFrame);
			}
		};

		frameTimer = requestAnimationFrame(runFrame);

		const setLastInteractedTimestamp = () => {
			lastInteractedTimestamp = performance.now();
		};

		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				setLastInteractedTimestamp();
				return;
			}

			lastFrameTimestamp = undefined;
		};

		const abortEvents = new AbortController();
		window.addEventListener('click', setLastInteractedTimestamp, {
			signal: abortEvents.signal,
		});
		window.addEventListener('mousemove', setLastInteractedTimestamp, {
			signal: abortEvents.signal,
		});
		window.addEventListener('keydown', setLastInteractedTimestamp, {
			signal: abortEvents.signal,
		});
		window.addEventListener('scroll', setLastInteractedTimestamp, {
			signal: abortEvents.signal,
		});
		window.addEventListener('visibilitychange', handleVisibilityChange, {
			signal: abortEvents.signal,
		});

		return () => {
			abortEvents.abort();

			cancelAnimationFrame(frameTimer);
		};
	}, [wordCount, urlPath]);
};
