'use client';

import { useAnalyticsPageMeta } from '@/app/api/push-event/_helpers/useAnalyticsPageMeta.client';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { position } from '@/uiPrimitives/layout/position';
import { zIndex } from '@/uiPrimitives/layout/zIndex.css';
import { pointerEventsNone } from '@/uiPrimitives/utility/pointerEvents.css';
import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	compensateForDisclaimerWhiteSpace,
	measureInViewCSS,
	popupScrollArea,
	popupShadow,
} from './PageFeedback.css';
import { ClosePopup } from './ClosePopup';
import { Translation } from '@/globals/translate/translations';
import { usePathname } from 'next/navigation';
import {
	pushFeedbackPopupClosedEvent,
	pushFeedbackPopupOpenedEvent,
} from '@/globals/analytics/events/pushFeedbackEvent';
import { pageGrid } from '@/uiPrimitives/layout/pageGrid/pageGrid';

const PageFeedbackPopupContext = createContext<
	| {
			onFieldChange: () => void;
			onSubmit: () => void;
			isPopping: boolean;
	  }
	| undefined
>(undefined);

export const usePageFeedbackPopup = () => {
	const context = useContext(PageFeedbackPopupContext);
	if (context === undefined) {
		throw new Error(
			'usePageFeedbackPopup must be used within a PageFeedbackPopupProvider',
		);
	}
	return context;
};

const getPageCount = () => {
	try {
		const value = Number(sessionStorage.getItem('feedbackPageCounter'));
		if (isNaN(value)) return 0;
		return value;
	} catch (error) {
		return 0;
	}
};

const savePageCount = (pageCount: number) => {
	try {
		sessionStorage.setItem('feedbackPageCounter', String(pageCount));
	} catch (error) {
		// NOOP
	}
};

/**
 * Count on every page, not just ones with feedback
 */
export const useIncrementFeedbackPageCount = () => {
	const savedForPageRef = useRef<string>();
	const pathname = usePathname();

	useEffect(() => {
		if (savedForPageRef.current === pathname) return;

		savedForPageRef.current = pathname;
		savePageCount(getPageCount() + 1);
	}, [pathname]);
};

const getHasBeenClosed = () => {
	try {
		const value = sessionStorage.getItem('feedbackHasBeenClosed');

		return value === 'true';
	} catch (error) {
		return false;
	}
};

const saveHasBeenClosed = (hasBeenClosed: boolean) => {
	const value = hasBeenClosed ? 'true' : 'false';
	try {
		sessionStorage.setItem('feedbackHasBeenClosed', value);
	} catch (error) {
		// NOOP
	}
};

export const PageFeedbackPopupProvider = ({
	children,
	popupEnabled,
	closeLabel,
	className,
}: {
	children: ReactNode;
	popupEnabled: boolean;
	closeLabel: Translation<'Close Feedback Popup'>;
	className?: string;
}) => {
	const measureInViewRef = useRef<HTMLElement>(null);
	const [isInView, setIsInView] = useState(false);

	useEffect(() => {
		if (measureInViewRef.current == null) return;

		const observer = new IntersectionObserver((entries) => {
			setIsInView(entries[0]?.isIntersecting ?? false);
		});

		observer.observe(measureInViewRef.current);

		return () => {
			observer.disconnect();
		};
	});

	const [isPopping, setIsPopping] = useState(false);
	const { wordCount } = useAnalyticsPageMeta();
	const scrollRef = useRef<HTMLElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// Check if popup could be shown on this page
		const pageCanShowPopup = () => {
			if (wordCount == null || wordCount < 400) return false;
			if (!popupEnabled) return false;
			if (getPageCount() < 3) return false;
			if (getHasBeenClosed()) return false;

			return true;
		};

		const isFeedbackInView = () => {
			const boundingRect = wrapperRef.current?.getBoundingClientRect();

			if (boundingRect == null) return false;

			const windowHeight = window.innerHeight;
			const windowWidth = window.innerWidth;

			const inView =
				boundingRect.top < windowHeight &&
				boundingRect.bottom > 0 &&
				boundingRect.left < windowWidth &&
				boundingRect.right > 0;

			return inView;
		};

		if (!pageCanShowPopup()) return;

		const showPopup = () => {
			// Check again in case conditions have changed
			if (!pageCanShowPopup()) return;
			if (isFeedbackInView()) return;

			savePageCount(0);
			setIsPopping(true);
			cleanUp();
			pushFeedbackPopupOpenedEvent();
		};

		const timer = setTimeout(showPopup, 2 * 60 * 1000);

		const observer = new IntersectionObserver((entries) => {
			if (entries[0]?.isIntersecting) {
				showPopup();
			}
		}, {});

		if (scrollRef.current != null) {
			observer.observe(scrollRef.current);
		}

		const cleanUp = () => {
			observer.disconnect();
			clearTimeout(timer);
		};

		return cleanUp;
	}, [popupEnabled, wordCount]);

	// Callbacks for feedback form
	const onFieldChange = useCallback(() => {
		savePageCount(0);
	}, []);
	const onSubmit = useCallback(() => {
		saveHasBeenClosed(true);
		savePageCount(0);
	}, []);
	const onClose = useCallback(() => {
		saveHasBeenClosed(true);
		savePageCount(0);
		setIsPopping(false);
		pushFeedbackPopupClosedEvent();
	}, []);

	return (
		<PageFeedbackPopupContext.Provider
			value={{
				onFieldChange,
				onSubmit,
				isPopping,
			}}
		>
			<i
				ref={scrollRef}
				className={classes(
					position({
						position: 'absolute',
						insetInline: '0',
						insetBlockStart: '50%',
						insetBlockEnd: '0',
					}),
					pointerEventsNone,
				)}
			/>
			<div
				ref={wrapperRef}
				className={classes(
					className,
					pageGrid({}),
					compensateForDisclaimerWhiteSpace,
					...(isPopping
						? [
								position({
									position: 'sticky',
									insetBlockEnd: '-1px',
								}),
								zIndex({ zIndex: 'feedback' }),
								!isInView && popupShadow,
							]
						: [zIndex({ isolation: 'isolate' })]),
				)}
			>
				<div
					className={classes(pageGrid({}), position({ position: 'relative' }))}
				>
					{isPopping && !isInView && (
						<ClosePopup onClick={onClose} closeLabel={closeLabel} />
					)}
					<div className={classes(pageGrid({}), isPopping && popupScrollArea)}>
						{children}
					</div>
					<i className={classes(measureInViewCSS)} ref={measureInViewRef} />
				</div>
			</div>
		</PageFeedbackPopupContext.Provider>
	);
};
