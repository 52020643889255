'use client';

import {
	createLocalStorageBooleanStore,
	useWebStorageStore,
} from '@/globals/webStorage/useWebStorage';
import React from 'react';
import { EditorWidgetConfig } from './EditorWidget.client';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { pointerEventsNone } from '@/uiPrimitives/utility/pointerEvents.css';
import { position } from '@/uiPrimitives/layout/position';
import dynamic from 'next/dynamic';
import { editorWidgetLoadingCSS } from './EditorWidget.css';
import { box } from '@/uiPrimitives/layout/box';
import { pile } from '@/uiPrimitives/layout/pile';

const LoadableEditorWidget = dynamic(
	() => import('./EditorWidget.client').catch(() => ({ default: () => null })),
	{
		loading: () => (
			<span
				className={classes(
					editorWidgetLoadingCSS,
					box({
						borderBlockEndStyle: 'solid',
						borderColor: 'positive',
						borderWidth: '4px',
						blockSize: '10 | betweenH2Sections',
					}),
				)}
			/>
		),
		ssr: false,
	},
);

const store = createLocalStorageBooleanStore({
	key: 'editorWidgetOpened',
});

export const EditorWidgetClientLoader = (
	props: EditorWidgetConfig,
): React.ReactElement | null => {
	const [opened, setOpened] = useWebStorageStore(store, props.hasCMSPreview);

	const onClose = React.useCallback(() => {
		setOpened(false);
	}, [setOpened]);

	React.useEffect(() => {
		window.dop ??= {};
		window.dop.openEditorWidget = () => {
			setOpened(true);
		};

		return () => {
			delete window.dop?.openEditorWidget;
		};
	}, [setOpened]);

	return opened ? (
		<div
			className={classes(
				pointerEventsNone,
				position({
					position: 'fixed',
					insetInlineEnd: '0',
					insetBlockStart: '0',
					blockSize: '100svb',
				}),
				pile({ alignItems: 'center' }),
			)}
		>
			<LoadableEditorWidget {...props} onClose={onClose} />
		</div>
	) : null;
};
