import { pushEvent } from '../pushEvent';

const eventName = `interaction.feedbackAction`;

const getLabel = ({
	name,
	changed,
	value,
	oldValue,
}: {
	name: string;
	changed: boolean;
	value: string;
	oldValue: string;
}) => {
	if (name === 'explanation') {
		return '-';
	}

	// explanation-text is not sent to GA (sensitive/super-long)
	if (changed) return `${oldValue} > ${value}`;

	return value;
};
export const pushFieldChangedEvent = ({
	name,
	value,
	oldValue,
	isPopping,
}: {
	name: string;
	value: string;
	oldValue: string;
	isPopping: boolean;
}) => {
	const changed = oldValue !== '' && oldValue !== value;
	if (name === 'explanation' && oldValue !== '') return;

	const label = getLabel({ name, changed, value, oldValue });

	pushEvent({
		events: {
			category: eventName,
			action: `${name}-${changed ? 'update' : 'set'} - page-feedback`,
			label,
		},
	});

	// We send this event after a delay to prevent it from overwriting the actual feedback-value to GA if these 2 calls are too close together
	window.setTimeout(
		() =>
			pushEvent({
				events: {
					category: eventName,
					action: `${name}-method - page-feedback`,
					label: isPopping ? 'sticky-bar' : 'bottom-page',
				},
			}),
		1000, // delay for 1 sec
	);
};

export const pushSubmittedEvent = ({
	ratingValue,
	isPopping,
}: {
	ratingValue: string;
	isPopping: boolean;
}) => {
	pushEvent({
		event: eventName,
		events: {
			category: eventName,
			action: `submit - page-feedback`,
			label: ratingValue, // send the rating on a submit (insights request)
		},
	});

	// We send this event after a delay to prevent it from overwriting the actual feedback-value to GA if these 2 calls are too close together
	window.setTimeout(
		() =>
			pushEvent({
				event: eventName,
				events: {
					category: eventName,
					action: `submit-method - page-feedback`,
					label: isPopping ? 'sticky-bar' : 'bottom-page',
				},
			}),
		1000, // delay for 1 sec
	);
};

export const pushFeedbackPopupOpenedEvent = () => {
	pushEvent({
		event: eventName,
		events: {
			category: eventName,
			action: 'show-bar - page-feedback',
			label: '-',
		},
	});
};

export const pushFeedbackPopupClosedEvent = () => {
	pushEvent({
		event: eventName,
		events: {
			category: eventName,
			action: 'close-bar - page-feedback',
			label: '-',
		},
	});
};
