import { getWindow } from './windowDocument';

export const getScrollTop = (win = getWindow()) => {
	if (win) {
		return 'pageYOffset' in win
			? win.pageYOffset
			: win.document.documentElement.scrollTop;
	}
	return null;
};

export const getScrollLeft = (win = getWindow()) => {
	if (win) {
		return 'pageXOffset' in win
			? win.pageXOffset
			: win.document.documentElement.scrollLeft;
	}
	return null;
};

export const setScroll = (leftOffset, topOffest, behavior = 'smooth') => {
	const win = getWindow();
	if (win) {
		const isSmoothScrollSupported =
			'scrollBehavior' in document.documentElement.style;
		if (isSmoothScrollSupported) {
			win.scrollTo({
				left: leftOffset,
				top: topOffest,
				behavior,
			});
		} else {
			win.scrollTo(leftOffset, topOffest);
		}
	}
};

export const setScrollTop = (offset) => {
	const win = getWindow();
	setScroll(getScrollLeft(win), offset);
};

export const setScrollLeft = (offset) => {
	const win = getWindow();
	setScroll(offset, getScrollTop(win));
};
