import { once, existy, negate, hasMethod, isNumeric } from './functional';
import { getWindow } from './windowDocument';
import { getDocument } from './windowDocument';

export const isDisabled = (element) => element.disabled === true;
export const isEnabled = negate(isDisabled);

export const isAriaHidden = (element) =>
	element.getAttribute('aria-hidden') === 'true';
export const isAriaVisible = negate(isAriaHidden);

const getTabIndex = (element) => element.getAttribute('tabindex');

export const hasTabIndex = (element) =>
	element.tabIndex >= 0 || isNumeric(getTabIndex(element));

// Where el is the DOM element you'd like to test for visibility
const isHiddenElement = (element) => {
	return element.offsetParent === null;
};
export const isVisibleElement = negate(isHiddenElement);

export const isFocusable = (element) =>
	existy(element) &&
	hasTabIndex(element) &&
	isEnabled(element) &&
	isAriaVisible(element);

export const getActiveElement = () => {
	const doc = getDocument();

	return doc && doc.activeElement;
};

export const setActiveElement = (element) => {
	if (!element) return;
	element.focus();
};

export const isFormElement = (element) => {
	const formElements = ['input', 'textarea', 'select', 'datalist', 'output'];
	const tagname = element && element.tagName && element.tagName.toLowerCase();

	return formElements.includes(tagname);
};

export const activeElementIsInputType = () => {
	const activeElement = getActiveElement();

	return isFormElement(activeElement);
};

/**
 * Check whether the given html element supports the blur function.
 *
 * @param {HTMLElement} element
 * @returns {boolean} - Returns true when the given element supports the blur function.
 */
export const isSupportingBlur = (element) => hasMethod('blur', element);

export const isSupportingCaptureOption = once(() => {
	let supportsCaptureOption = false;

	try {
		const opts = Reflect.defineProperty({}, 'capture', {
			// eslint-disable-next-line getter-return
			get() {
				supportsCaptureOption = true;
			},
		});

		window.addEventListener('test', null, opts);
	} catch (e) {
		// Catch
	}

	return supportsCaptureOption;
});

const getOptionsOrCapture = (options) =>
	isSupportingCaptureOption() || typeof options !== 'object'
		? options
		: options.capture;

export const addEventListenerWithOptions = ({
	target,
	eventName,
	handler,
	options,
}) => {
	const optionsOrCapture = getOptionsOrCapture(options);

	target.addEventListener(eventName, handler, optionsOrCapture);

	return () => {
		target.removeEventListener(eventName, handler, optionsOrCapture);
	};
};

export const beforeNode = Symbol('beforeNode');
export const sameNode = Symbol('sameNode');
export const afterNode = Symbol('afterNode');

export const nodeRelativePositionComparator = (leftNode, rightNode) => {
	const win = getWindow();

	if (!win || !leftNode || !rightNode) {
		return sameNode;
	}

	const result = leftNode.compareDocumentPosition(rightNode);
	const Node = win.Node;

	if (
		result &
		(Node.DOCUMENT_POSITION_PRECEDING | Node.DOCUMENT_POSITION_CONTAINS)
	) {
		//eslint-disable-line no-bitwise
		return beforeNode;
	} else if (
		result &
		(Node.DOCUMENT_POSITION_FOLLOWING | Node.DOCUMENT_POSITION_CONTAINED_BY)
	) {
		//eslint-disable-line no-bitwise
		return afterNode;
	}

	return sameNode;
};

export const disableIframesTab = () => {
	const iframes = document.querySelectorAll('iframe:not([data-focusable])');
	for (const el of iframes) {
		el.setAttribute('aria-hidden', 'true');
		el.setAttribute('tabindex', -1);
	}
};

export const enableIframesTab = () => {
	const iframes = document.querySelectorAll('iframe:not([data-focusable])');
	for (const el of iframes) {
		el.setAttribute('aria-hidden', 'false');
		el.removeAttribute('tabindex');
	}
};
