import { getDocument } from './windowDocument';
import { unexisty, once } from './functional';

export const addRulerElement = once(() => {
	const doc = getDocument();
	if (unexisty(doc)) {
		return null;
	}

	const body = doc.body;
	const rulerElement = doc.createElement('div');

	Object.assign(rulerElement.style, {
		zIndex: -1000,
		position: 'fixed',
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		width: 'auto',
		height: 'auto',
		margin: 0,
		padding: 0,
		visibility: 'hidden',
		pointerEvents: 'none',
	});

	body.appendChild(rulerElement);

	return rulerElement;
});

export const getWindowBoundingClientRect = () => {
	const rulerElement = addRulerElement();

	return rulerElement.getBoundingClientRect();
};

export const getWindowSize = () => {
	const { width, height } = getWindowBoundingClientRect();
	const windowSize = { width, height };

	return windowSize;
};
