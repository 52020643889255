/**
 * @module helpers/functional
 */

import curry from './curry';

const decimalBase = 10;

export const result = (input, ...args) => {
	if (typeof input === 'function') {
		return input(...args);
	} else {
		return input;
	}
};

export const parseJSON = (json) => {
	try {
		return JSON.parse(json);
	} catch (error) {
		return null;
	}
};

export const roundTo = (precision) => (number) => {
	const multiplier = Math.pow(decimalBase, precision);

	return Math.round(number * multiplier) / multiplier;
};

// eslint-disable-next-line no-prototype-builtins
export const isErrorObject = (obj) => Error.prototype.isPrototypeOf(obj);

export const tens = -1;
export const roundToTens = roundTo(tens);

export const percentageTotal = 100;
export const percentage = (fraction) => percentageTotal * fraction;
export const fraction = (percentageInput) => percentageInput / percentageTotal;

export const once = (func) => {
	let memo;

	return (...args) => {
		if (!func) {
			return memo;
		}
		memo = func(...args);
		func = null; // eslint-disable-line no-param-reassign
		return memo;
	};
};

export const negate =
	(fn) =>
	(...args) =>
		!fn(...args);
export const boolify =
	(fn) =>
	(...args) =>
		Boolean(fn(...args));

export const pipe =
	(...fns) =>
	(...args) =>
		fns.reduce((lastReturned, fn) => [fn(...lastReturned)], args)[0];
export const and =
	(...fns) =>
	(...args) =>
		fns.every((fn) => fn(...args));
export const or =
	(...fns) =>
	(...args) =>
		fns.some((fn) => fn(...args));

export const unexisty = (value) =>
	value === null || typeof value === 'undefined';

export const existy = negate(unexisty);

export const add = (a) => (b) => a + b;
export const increment = add(1);

export const defaultTo = (defaultValue) => (value) =>
	existy(value) ? value : defaultValue;

export const isFunction = (input) => typeof input === 'function';

export const isNotFunction = negate(isFunction);

// Only execute func if it is a function, otherwise execute a noop function
// Example: safelyExecute(func)('arg1', 'arg2') executes func with 'arg1' and 'arg2'
export const safelyExecute = (func) =>
	typeof func === 'function' ? func : () => {};

export const isElement = (target) => target instanceof Element;

/**
 * @deprecated - use isFilled function from helpers/immutableHelpers.js instead.
 */
export const isFilled = (iterable) =>
	existy(iterable) && iterable.isEmpty && !iterable.isEmpty();

export const hasMinimumLength = (minimum) => (iterable) =>
	existy(iterable) && iterable.size >= minimum;

/**
 * @deprecated - use isEmpty function from helpers/immutableHelpers.js instead.
 */
export const isEmpty = negate(isFilled);

export const contains = (list, propName) => list.indexOf(propName) >= 0;
export const notContains = negate(contains);

export const without = (obj = {}, ...excludedPropNames) =>
	Object.keys(obj).reduce((newObj, objPropName) => {
		if (notContains(excludedPropNames, objPropName)) {
			newObj[objPropName] = obj[objPropName]; //eslint-disable-line no-param-reassign
		}

		return newObj;
	}, {});

export const reduceObject = (iterator, startValue, obj) =>
	Object.keys(obj).reduce(
		(memo, key) => iterator(memo, obj[key], key),
		startValue,
	);

export const delayed = (delay, callback, ...args) => {
	if (unexisty(delay) || delay === 0) {
		callback(...args);
		return null;
	} else {
		return setTimeout(() => callback(...args), delay);
	}
};

export const debounce = (callback, delay) => {
	let timer;

	const debounced = (...args) => {
		clearTimeout(timer);
		timer = delayed(delay, callback, ...args);
	};

	debounced.now = (...args) => {
		clearTimeout(timer);
		callback(...args);
	};

	debounced.clear = () => {
		clearTimeout(timer);
	};

	return debounced;
};

// Higher priority calls cancel lower priority calls
// Lower priority calls never cancel higher priority calls

export const prioritizedDebounce = (delay) => {
	let timer;
	let timerPriority = 0;

	const debounced = curry((priority, callback, args) => {
		if (priority >= timerPriority) {
			clearTimeout(timer);
			timerPriority = priority;

			timer = delayed(delay, () => {
				timerPriority = 0;
				callback(...args);
			});
		}
	});

	return debounced;
};

export const isAscendantOf = (parent, child) => {
	if (parent === child) {
		return true;
	}

	const nextChild = child.parentElement;

	if (!nextChild) {
		return false;
	}

	return isAscendantOf(parent, nextChild);
};

export const equals = curry(Object.is);

export const equalOrMatch = curry((value, matcher) =>
	matcher instanceof RegExp ? matcher.test(value) : matcher === value,
);

export const isNumeric = (num) => existy(num) && !isNaN(num);

export const isNumber = (num) => existy(num) && typeof num === 'number';

export const isNotNumber = negate(isNumber);

export const isObject = (obj) => existy(obj) && typeof obj === 'object';

/**
 * Check is the given entity is an array.
 *
 * @param {string[]|number[]|object[]} array
 * @returns {boolean} - returns true if the given entity exists and is an array.
 */
export const isArray = (array) => existy(array) && Array.isArray(array);

/**
 * Check is the given entity is not an array.
 *
 * @param {any} array
 * @returns {boolean} - returns true if the given entity is not an array.
 */
export const isNotArray = negate(isArray);

/**
 * Check is the given entity is a filled array.
 *
 * @param {string[]|number[]|object[]} array
 * @returns {boolean} - returns true if the given entity exists and is an array and is filled.
 */
export const isFilledArray = (array) => isArray(array) && array.length > 0;

/**
 * Check is the given entity is an empty array.
 *
 * @param {string[]|number[]|object[]} array
 * @returns {boolean} - returns true if the given entity is not filled.
 */
export const isEmptyArray = negate(isFilledArray);

/**
 * Check whether a given entity is an object containing one or more keys.
 *
 * @param {object} object
 * @returns {boolean} - returns true when the given entity, exists, is an object and has one or more keys.
 */
export const isFilledObject = (object) =>
	isObject(object) && Object.keys(object).length > 0;

/**
 * Check whether a given entity is an empty object or non-existent.
 *
 * @param {object} object
 * @returns {boolean} - returns true when the given entity doesn't exist, is not an object or has no keys.
 */
export const isEmptyObject = negate(isFilledObject);

export const getInObject = curry(([key, ...restKeys], obj) => {
	const normalizedObj = isObject(obj) ? obj : {};

	if (restKeys.length === 0) {
		return normalizedObj[key];
	} else {
		return getInObject(restKeys, normalizedObj[key]);
	}
});

/* eslint-disable no-magic-numbers */
export const isNegativeNumber = (input) => Math.sign(input) === -1;

export const toPositiveNumber = (input) => {
	if (input === 0) return input;

	return isNegativeNumber(input) ? input * -1 : input;
};
/* eslint-disable no-magic-numbers */

/** @depracated - Use default es6 destructuring to assign props in objects, or Immer library to update nested props */
export const set = curry((key, value, obj) => {
	if (obj == null || (key in obj && obj[key] === value)) {
		return obj;
	} else {
		const newObj = { ...obj, [key]: value };

		return newObj;
	}
});

/** @depracated - Use Immer library to update nested props in objects */
export const setIn = curry(([key, ...restKeys], value, obj) => {
	const newObj = existy(obj) ? obj : {};

	if (restKeys.length) {
		return set(key, setIn(restKeys, value, newObj[key]), newObj);
	} else if (existy(key)) {
		return set(key, value, newObj);
	} else {
		return newObj;
	}
});

/** @depracated - Use Immer library to update nested props in objects */
export const updateInObject = curry((keys, updater, obj) => {
	if (unexisty(obj)) {
		return obj;
	}
	const value = updater(getInObject(keys, obj));

	return setIn(keys, value, obj);
});

export const pluck = curry((keys, obj) => {
	if (unexisty(obj)) {
		return obj;
	} else {
		return keys.reduce((newObj, key) => set(key, obj[key], newObj), {});
	}
});

// Helper method to check if the object supports the method
export const hasMethod = (methodName, obj) =>
	existy(obj) && typeof obj[methodName] === 'function';

export const arrify = (obj) => (Array.isArray(obj) ? obj : [obj]);

export const startsWith = (string = '', searchString) =>
	string.lastIndexOf(searchString, 0) === 0;

export const endsWith = (string = '', searchString) =>
	string.endsWith(searchString);

export const trimEndString = (string = '', removeString) =>
	string.substring(0, string.indexOf(removeString));

/**
 * Trims the part of the given string before and including the searchString.
 * @param {string} string - The target haystack.
 * @param {string} searchString - The needle to search for.
 *
 * @returns {string} - Returns a trimmed string, or the entire string if the searchString was not found.
 */
export const trimStringBefore = (string = '', searchString) =>
	string.substring(string.indexOf(searchString) + searchString.length);

/**
 * Trims a string, if it is a string, otherwise returns the input.
 * @param {*} string - The target string or other (e.g. undefined)
 */
export const safeTrim = (string) =>
	typeof string === 'string' ? string.trim() : string;

export const startsWithCaseInsensitive = (string = '', searchString = '') =>
	startsWith(string.toLowerCase(), searchString.toLowerCase());

export const immutableToObject = (immutableObject) =>
	isFilled(immutableObject) ? immutableObject.toObject() : {};

export const testRegExp = (regExp) => (str) => regExp.test(str);

export const mapObject = (iterator, obj) =>
	Object.keys(obj).reduce((memo, key) => {
		// eslint-disable-next-line no-param-reassign
		memo[key] = iterator(obj[key], key, obj);
		return memo;
	}, {});

/**
 * ternaryPredicator
 *
 * @callback ternaryPredicator
 * @param {object} cases - callbacks
 * @property {callback} [cases.true] - is called when predicate is true
 * @property {callback} [cases.false] - is called when predicate is false
 * @returns {module:helpers/functional~ternaryExecuter}
 */

/**
 * ternaryExecuter
 *
 * @callback ternaryExecuter
 * @param {*} arg - argument that will be passed to predicate and case callbacks
 * @returns {*|null} returns what ever the case callback returns, when no callback is provided returns null
 */

/**
 * same as predicate(arg) ? trueFn(arg) : falseFn(arg)
 *
 * @function
 * @param {callback} predicate - determines if true or false callback should be called
 * @returns {module:helpers/functional~ternaryPredicator}
 */
export const ternary =
	(predicate) =>
	({ true: trueFn, false: falseFn }) =>
	(arg) =>
		predicate(arg) ? safelyExecute(trueFn)(arg) : safelyExecute(falseFn)(arg);

export const ternaryReturn = (predicate) => (trueValue, falseValue) => (arg) =>
	predicate(arg) ? trueValue : falseValue;

const getCase = (outcome, cases) => cases[outcome] || cases.default;

export const switcher = (predicate) => (cases) => (arg) =>
	safelyExecute(getCase(predicate(arg), cases))(arg);
