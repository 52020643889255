import { ArrangeProps, arrange } from './arrange';

/**
 * Component for stacking items vertically
 *
 * Safari has performance problems when stacking large amounts
 * of items with `display: grid`.
 *
 * Responsibilities:
 *
 * 1. Stack items on top of each other
 * 2. Spacing between items
 * 3. Alignment of items
 */
export const stack = (props: Omit<ArrangeProps<'stack'>, 'layout'>) => {
	return arrange({ ...props, layout: 'stack' });
};
